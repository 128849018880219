import React from "react";
import styled from "styled-components";

const SearchContainer = styled.div`
	width: ${(props) => (props.width ? props.width : "80px")};
	position: absolute;
	top: 20px;
	left: 111px;
	width: 45%;
	z-index: 9;
`;

const Title = styled.p`
	font-family: 'Times New Roman', times , serif;
	text-decoration underline;
	font-size: 29px;
	line-height: 1.3;
	color: #000;
	opacity: 0.6;
	margin: 16px 120px 0px 10px;
`

const Input = styled.input.attrs((props) => ({
	type: props.type || "text",
	placeholder: props.title || "Search: Here",
	value: props.value || "",
	onChange: props.onChangeFunction || null,
}))`
	width: 100%;
	margin: 12px 10px 10px;
	padding: 0;
	border: 0;
	outline: 0;
	font-family: 'Times New Roman', times , serif;
	background: transparent;
	border-bottom: 0;
	text-decoration underline;
	font-size: inherit;
	line-height: 1.5;

	&::placeholder {
	  color: #000;
	}
`;

/** Reusable search bar that can take in a filter function */
const SearchBar = (props) => {
	return (
		<SearchContainer {...props}>
			<Input {...props} />
		</SearchContainer>
	);
};

export default SearchBar;
